export const naratives = {
    "EU": {
        "Major Risk": "GDPR applies to all organizations that process personally data of EU citizens and will allow EU citizens to continuously monitor personal data.    All organizations must ensure their compliance with GDPR requirements.  Even more so, GDPR applies to companies in the European Union and organizations outside of the EU.  The following guidances apply:  1) The primary goal is to evaluate and review current vulnerabilities to sensitive information including personally private information and have a thorough breach response strategy and comprehensive remediation plan in place.  2) Organizations that have a Compliance Impact Score in this range must employ a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) with an extensive proactive management strategy to develop internal controls and procedures that promote adherence to applicable regulations. 3) The costs are minor compared to the risk of non-compliance even though the implementation of an effective compliance program may require a significant commitment of time and resources.",
        "Cautionary Risk":"GDPR applies to all organizations that process personally data of EU citizens and will allow EU citizens to continuously monitor personal data.    All organizations must ensure their compliance with GDPR requirements.  Even more so, GDPR applies to companies in the European Union and organizations outside of the EU.  The following guidances apply:     1) The primary goal for an organization is identify potential issues in the architecture of the security infrastructure and to develop procedures that promote adherence to regulations. 1) Organizations that have a Compliance Impact Score in this range must employ Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) and have a proactive management strategy. 2)  Organizations with cautionary compliance risk are affected by high impact regulations that have significant fines and penalties for failure to comply.  3) The costs are minor compared to the risk of non-compliance even though the implementation of an effective compliance program may require a significant commitment of time and resources.",
        "Moderate Risk":"GDPR applies to all organizations that process personally data of EU citizens and will allow EU citizens to continuously monitor personal data.      All organizations must ensure their compliance with GDPR requirements.  Even more so, GDPR applies to companies in the European Union and organizations outside of the EU.  The following guidances apply: 1) The primary goal for an organization is to build an information processing environment to not only protect and secure, but also support day-to-day business initiatives and promote adherence to regulations.  2) Organization that have a Compliance Impact Score in this range should have a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) and a proactive management strategy.   3) Even though the implementation of an effective compliance program may require a significant commitment of time and resources the benefit outweighs the risk of compliance.",
        "Minor Risk": "GDPR applies to all organizations that process personally data of EU citizens and will allow EU citizens to continuously monitor personal data.    All organizations must ensure their compliance with GDPR requirements.  Even more so, GDPR applies to companies in the European Union and organizations outside of the EU.  The following guidances apply:  1) The primary goal for an organization is to have strong communication, enhance productivity, and alignment to the business priorities established by senior leadership.  2) Designation of an individual directly responsible for ensuring compliance and executing the company’s compliance program is a minimum requirement.    3) The long-term benefit of establishing a compliance program significantly outweigh the initial costs. ",
        "Acceptable Risk": "GDPR applies to all organizations that process personally data of EU citizens and will allow EU citizens to continuously monitor personal data.   All organizations must ensure their compliance with GDPR requirements.  Even more so, GDPR applies to companies in the European Union and organizations outside of the EU.  The following guidances apply: 1) The primary goal is to meet applicable regulations and identify opportunities to improve the compliance program.  2) A minimum requirement is to designate an individual to track the EU and industry standards as well as monitor the organization’s compliance program.   4) The benefit of implementing an effective compliance program outweigh the initial cost."
    },
    "USA":{
        "Major Risk":"Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal is to evaluate and review current vulnerabilities to sensitive information including personnel private information and have a thorough breach response strategy and comprehensive remediation plan in place.  2) Organizations that have a Compliance Impact Score in this range must employ a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) with an extensive proactive management strategy to develop internal controls and procedures that promote adherence to applicable regulations. 3) The costs are minor compared to the risk of non-compliance even though the implementation of an effective compliance program may require a significant commitment of time and resources. ",
        "Cautionary Risk":"Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:   1) The primary goal for an organization is identify potential issues in the architecture of the security infrastructure and to develop procedures that promote adherence to regulations. 1) Organizations that have a Compliance Impact Score in this range must employ a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) and have a proactive management strategy. 2)  Organizations with cautionary compliance risk are affected by high impact regulations that have significant fines and penalties for failure to comply.  4) Even though the implementation of an effective compliance program may require a significant commitment of time and resources by the organization, the costs outweigh the risk of non-compliance.",
        "Moderate Risk":"Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal for an organization is to build an information processing environment to not only protect and secure, but also support day-to-day business initiatives and promote adherence to regulations.  2) Organization that have a Compliance Impact Score in this range should have a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) and a proactive management strategy.   3) Even though the implementation of an effective compliance program may require a significant commitment of time and resources the benefit outweighs the risk of compliance.",
        "Minor Risk":"Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal for an organization is to have strong communication, enhance productivity, and alignment to the business priorities established by senior leadership.  2) Designation of an individual directly responsible for ensuring compliance and executing the company’s compliance program is a minimum requirement.    3) The long-term benefit of establishing a compliance program significantly outweigh the initial costs. ",
        "Acceptable Risk":"Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal is to meet applicable regulations and identify opportunities to improve the compliance program.  2) A minimum requirement is to designate an individual to track the federal regulations and industry standards as well as monitor the organization’s compliance program.   4) The benefit of implementing an effective compliance program outweigh the initial cost."
    },
    "Both": {
        "Major Risk": "Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal is to evaluate and review current vulnerabilities to sensitive information including personnel private information and have a thorough breach response strategy and comprehensive remediation plan in place.  2) Organizations that have a Compliance Impact Score in this range must employ a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) with an extensive proactive management strategy to develop internal controls and procedures that promote adherence to applicable regulations. 3) The costs are minor compared to the risk of non-compliance even though the implementation of an effective compliance program may require a significant commitment of time and resources. ",
        "Cautionary Risk": "Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:   1) The primary goal for an organization is identify potential issues in the architecture of the security infrastructure and to develop procedures that promote adherence to regulations. 1) Organizations that have a Compliance Impact Score in this range must employ a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) and have a proactive management strategy. 2)  Organizations with cautionary compliance risk are affected by high impact regulations that have significant fines and penalties for failure to comply.  4) Even though the implementation of an effective compliance program may require a significant commitment of time and resources by the organization, the costs outweigh the risk of non-compliance.",
        "Moderate Risk": "Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal for an organization is to build an information processing environment to not only protect and secure, but also support day-to-day business initiatives and promote adherence to regulations.  2) Organization that have a Compliance Impact Score in this range should have a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) and a proactive management strategy.   3) Even though the implementation of an effective compliance program may require a significant commitment of time and resources the benefit outweighs the risk of compliance.",
        "Minor Risk": "Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal for an organization is to have strong communication, enhance productivity, and alignment to the business priorities established by senior leadership.  2) Designation of an individual directly responsible for ensuring compliance and executing the company’s compliance program is a minimum requirement.    3) The long-term benefit of establishing a compliance program significantly outweigh the initial costs. ",
        "Acceptable Risk": "Cybersecurity practices are a key focus given the evolving nature, increasing frequency, and sophistication of cybersecurity attacks – as well as the potential for harm to individuals,  investors, firms, and the markets.  Each organization must review its compliance with the following guidance and regulations listed below:  1) The primary goal is to meet applicable regulations and identify opportunities to improve the compliance program.  2) A minimum requirement is to designate an individual to track the federal regulations and industry standards as well as monitor the organization’s compliance program.   4) The benefit of implementing an effective compliance program outweigh the initial cost."
    },

    "composite_GRS" : {
        "info": "The Governance Risk Score (GRS) is a proprietary calculation derived from individual ratings obtained through four Q&A based sections that identify risks that can adversely affect an organization's business objectives, including its handling of data assets. Results in GRS are based solely on user’s input which are represented by the following: 1) Administrative Governance, 2) Physical Security, 3) Internal Network Security and, 4) External Network Security."
    }
}