import { Component, OnInit } from '@angular/core';
import { AirtableService } from "./../../services/airtable.service";
import { CONFIG } from './../../config/config'
import { AuthService } from './../../services/auth.service'
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-crp',
  templateUrl: './crp.component.html',
  styleUrls: ['./crp.component.scss']
})
export class CrpComponent implements OnInit {
  loading: boolean = false
  form: FormGroup;
  Token_ID: any = window.localStorage.getItem('token') || null
  crp: any = {
    organisation_name: "",   
    global_footprint: "",
    legal_domicile: "",
    organisation_address: "",
    type_of_entity: "",      
    primary_contact: "",
    organisation_website: "",
    organisation_type: "",
    countryIds: []
  }
  crp_data: any
  crp_status: any
  countriesData: any
  showCountryDropdown: any = false

  constructor(private airtable: AirtableService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
  ) {

    if (!this.Token_ID) {
      window.location.href = "login"
    }
  }
  ngOnInit() {
    this.route.params.subscribe(data => {
      console.log("getting all Details of course , the Course ID is: ", data);
      if (data.status == 'true') {
        this.crp_status = 'Update'
        this.getUserInfo()
      } else {
        this.crp_status = 'Create'
      }
      this.getCountriesData();
    }, error => {
      console.log(error);
    })


    this.form = this.fb.group({
      organisation_name: ['', Validators.required],
      global_footprint: ['', Validators.required],
      legal_domicile: ['', Validators.required],
      organisation_website: ['', Validators.required],
      type_of_entity: ['', Validators.required],
      primary_contact: ['', Validators.required],
      organisation_address: ['', Validators.required],    
      organisation_type: ['', Validators.required], 
      countryIds:  ['', Validators.required]
    });
    // this.getCrpData();
   
  }


  async getCrpData() {
    try {
      let res = await this.airtable.getCrpData();
      this.crp_data = res;
      this.crp_data = this.crp_data.success;
      console.log(res, 'res user');
      if (this.crp_data.length > 0) {
        this.crp_data = this.crp_data[0];
        this.form.setValue({
          organisation_name: this.crp_data.organisation_name,
          global_footprint: this.crp_data.global_footprint,
          legal_domicile: this.crp_data.legal_domicile,
          drganisation_address: this.crp_data.organisation_address,         
          primary_contact: this.crp_data.primary_contact,
          organisation_type: this.crp_data.organisation_type,
          organisation_website: this.crp_data.organisation_website,          
          type_of_entity: this.crp_data.type_of_entity,
          countryIds: this.crp_data.countryIds
        });

        window.localStorage.setItem('footprint', this.crp.global_footprint)
      }

    } catch (error) {
      console.log(error, 'error from get crp');
    }
  }

  // createCrp(e) {
  //   e.preventDefault()
  //   this.loading = true
  //   for (let i in this.crp) {
  //     this.crp[i] = this.form.controls[i].value
  //   }
  //   this.crp.Token_ID = this.Token_ID
  //   console.log(this.crp, "crp");
  //   window.localStorage.setItem('footprint', this.crp_data.Global_Footprint);
  //   this.airtable.postCrpData(this.crp).then(res => {
  //     console.log('The response after doing the put request is');
  //     console.log(res);     
  //     this.loading = false
  //     window.localStorage.setItem('footprint', this.crp_data.Global_Footprint)
  //     // window.location.reload();
  //     // window.location.href = "home"
  //     // setTimeout(() => {
  //       this.router.navigate(['/home'])
  //     // }, 500);

  //   }).catch(err => {
  //     console.log(err, "logg");
  //     this.loading = false
  //   })
  // }


  async createCrp(e) {
    e.preventDefault()
    this.loading = true
    for (let i in this.crp) {
      console.log(i,this.form.value,this.form.controls[i].value);
      if(i == 'countryIds' && this.form.controls[i].value == null) {
          this.crp[i] = [158];
      } else {
          this.crp[i] = this.form.controls[i].value
      }
    }
    this.crp.Token_ID = this.Token_ID
    console.log(this.crp, "crp");
    window.localStorage.setItem('footprint', this.crp.global_footprint);
    try {
      let res = await this.airtable.crpInfo(this.crp)
      let response = res.json()
      if (response.success) {
        this.router.navigate(['/home'])
        console.log('success');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getUserInfo() {
    try {
      let res = await this.authService.profile()
      let response = res.json()
      if (response.success) {
        for (let key in response.user) {
          if(this.form.controls[key]){         
            this.form.controls[key].setValue(response.user[key])
          }
          if (key == "global_footprint" && response.user[key] == 'GLOBAL') {
              this.showCountryDropdown = true;
          }
          
          
          // for (let val in this.crp) {
          //   if (key == val) {
          //     this.crp[key] = response.user[key]
          //   }
          // }
        }
      }
            
    } catch (error) {
      console.log(error);
    }
  }

  async getCountriesData() {
    try {
       let res = await this.authService.commonData()
       let response = res.json()
       if (response.success) {
        	 this.countriesData = response.data;
       }
            
    } catch (error) {
      console.log(error);
    }
  }

  onGlobalFootprintChange(value) {
      console.log(value);
      if(value == 'GLOBAL') { 
          this.form.controls["countryIds"].setValue([])
          this.showCountryDropdown = true;
      } else {
          this.showCountryDropdown = false;
          let defualtCountry = [];
          if(this.countriesData && this.countriesData.countries.length > 0){
		  let findCountry = this.countriesData.countries.find((ele)=>{
		   if(ele.country_name == "United States of America"){
		   	return true;
		   }
		   return false;
		  })
		  if(findCountry && findCountry.id){
		  	 defualtCountry = [findCountry.id]
		  }
          }	
          this.form.controls["countryIds"].setValue(defualtCountry)
          
      }
  }
}
