import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-registry-profile',
  templateUrl: './registry-profile.component.html',
  styleUrls: ['./registry-profile.component.scss']
})
export class RegistryProfileComponent implements OnInit {
  @Input() crp_data: any;
  closedPanel : boolean = false
  constructor() { }

  ngOnInit() {
    console.log("The crp data is")
    console.log(this.crp_data);
  }

}
