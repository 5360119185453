import { Injectable } from '@angular/core';
import { CONFIG } from './../config/config'
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AirtableService {
  headers: HttpHeaders;
  uid: any = window.localStorage.getItem('uid')
  footprint: any = `${CONFIG.footprint()}`;
  constructor(
    private _http: HttpClient,
    private http: Http,
    private router: Router, ) {
    this.footprint = this.getFootprint();
  }

  getlocations() {
    return this.http.get('')
  }

  getUid() {
    return window.localStorage.getItem('uid')
  }

  getFootprint() {
    return window.localStorage.getItem('footprint')
  }

  /////////////////////////////
  /// this code for CIS Section
  /////////////////////////////

  // get class names of bussiness class
  getBussinessClasses() {
    // console.log(this.footprint,'fooot');

    let url;
    this.footprint = this.getFootprint();

    if (this.footprint && (this.footprint === 'EU')) {
      url = `${CONFIG.API_URL}/UIDEUCoding/classNames`;
    } else if (this.footprint && (this.footprint === 'Both')) {
      url = `${CONFIG.API_URL}/UIDCombinedCoding/classNames`;
    } else {
      url = `${CONFIG.API_URL}/UIDUSACoding/classNames`;
    }
    console.log("hitting " + this.footprint, url);

    return this.http.get(url).toPromise();
  }

  getBussinessSubClasses(category) {

    this.footprint = this.getFootprint();

    let url;
    if (this.footprint && this.footprint == 'EU') {
      url = `${CONFIG.API_URL}/UIDEUCoding/subClasses?className=${category}`;
    } else if (this.footprint && this.footprint == 'Both') {
      url = `${CONFIG.API_URL}/UIDCombinedCoding/subClasses?className=${category}`;
    } else {
      url = `${CONFIG.API_URL}/UIDUSACoding/subClasses?className=${category}`;
    }

    return this.http.get(url).toPromise();
  }


  getRegularCategory(className, subClass) {

    let url;

    this.footprint = this.getFootprint();

    if (this.footprint && this.footprint == 'EU') {
      url = `${CONFIG.API_URL}/UIDEUCoding/regulatoryCategory?className=${className}&subClassName=${subClass}`;
    } else if (this.footprint && this.footprint == 'Both') {
      url = `${CONFIG.API_URL}/UIDCombinedCoding/regulatoryCategory?className=${className}&subClassName=${subClass}`;
    } else {
      url = `${CONFIG.API_URL}/UIDUSACoding/regulatoryCategory?className=${className}&subClassName=${subClass}`;
    }
    return this.http.get(url).toPromise();
  }

  // UIDCombinedCoding
  getImpactScores(className, subClass, regCategory) {
    let url;

    this.footprint = this.getFootprint();

    if (this.footprint && this.footprint == 'EU') {
      url = `${CONFIG.API_URL}/UIDEUCoding/regulationName?className=${className}&subClassName=${subClass}&regulatoryCategory=${regCategory}`;
    } else if (this.footprint && this.footprint == 'Both') {
      url = `${CONFIG.API_URL}/UIDCombinedCoding/regulationName?className=${className}&subClassName=${subClass}&regulatoryCategory=${regCategory}`;
    } else {
      url = `${CONFIG.API_URL}/UIDUSACoding/regulationName?className=${className}&subClassName=${subClass}&regulatoryCategory=${regCategory}`;
    }
    return this.http.get(url).toPromise();
  }


  getBussinessMetricSubclass(className) {
    let url = `${CONFIG.API_URL}/businessmetric?class=${className}`
    return this.http.get(url).toPromise();
  }

  getUicValue(number) {
    let url = `${CONFIG.API_URL}/uic?number=${number}`
    return this.http.get(url).toPromise();
  }


  getRegMetrics(name) {
    let url = `${CONFIG.API_URL}/regmetrics?name=${name}`
    return this.http.get(url).toPromise();
  }

  getLocationRiskMetric(location) {
    let url = `${CONFIG.API_URL}/location?location=${location}`
    return this.http.get(url).toPromise();
  }

  getImpactScoreForImact_reg(className, subClass) {

    this.footprint = this.getFootprint();

    let url;
    if (this.footprint && this.footprint == 'EU') {
      url = `${CONFIG.API_URL}/UIDEUCoding/impactScore?className=${className}&subClassName=${subClass}`;
    } else if (this.footprint && this.footprint == 'Both') {
      url = `${CONFIG.API_URL}/UIDCombinedCoding/impactScore?className=${className}&subClassName=${subClass}`
    } else {
      url = `${CONFIG.API_URL}/UIDUSACoding/impactScore?className=${className}&subClassName=${subClass}`
    }
    return this.http.get(url).toPromise();
  }


  getEmployeesCount() {
    let url = `${CONFIG.API_URL}/allEmployees`;
    return this.http.get(url).toPromise();
  }

  getRevenuesCount() {
    let url = `${CONFIG.API_URL}/revenue?revenue='rev'`;
    return this.http.get(url).toPromise();
  }


  postCrpData(data) {
    let url = `${CONFIG.API_URL}/crp`
    return this.http.put(url, data).toPromise();
  }

  getCrpData() {
    let uid = this.getUid();
    let url = `${CONFIG.API_URL}/userEntry?token=${uid}`;
    return this.http.get(url).toPromise();
  }


  getCrpDataWithLogin(uid) {
    let url = `${CONFIG.API_URL}/userEntry?token=${uid}`;
    return this.http.get(url).toPromise();
  }

  putScores(data) {
    let new_data = {
      Token_ID: this.getUid(),
      CIS_Score: data.cis.toString(),
      GRS_Score: data.grs.toString(),
      CSS_Score: data.css.toString()
    }
    let url = `${CONFIG.API_URL}/scores`
    return this.http.put(url, new_data).toPromise();
  }



  getScores() {
    let uid = this.getUid();
    // let url = `${CONFIG.API_URL}/userscores?token=${uid}`;
    let url = `${CONFIG.API_URL}/composite_score/user_scores?token=${uid}`
    return this.http.get(url).toPromise();
  }

  crpInfo(data) {
    let headers = new Headers({
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': window.localStorage.getItem('token')
    });
    let url = `${CONFIG.API_URL}/profile/update`
    return this.http.put(url, data, { headers: headers }).toPromise()
  }

  crpInfoNew(data) {
    let headers = new Headers({
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': window.localStorage.getItem('token')
    });
    let url = `${CONFIG.API_URL}/calculateScore`
    return this.http.post(url, data, { headers: headers }).toPromise()
  }
}
